/**
 * Import modules
 */
import React, { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { ImPlus } from 'react-icons/im';
import { IoClose } from 'react-icons/io5';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './SubventionRelationInfos.scss';
import contents from '../../contents/contents.json';

const SubventionRelationInfos = ({ editable, handleEditSubmit }) => {
	const { init, user } = useUserContext();
	const {
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
	} = useSubventionContext();

	const [showRemoveProject, setShowRemoveProject] = useState(false);
	const [showRemoveReciever, setShowRemoveReciever] = useState(false);

	const handleAddProject = async () => {
		const currentProjects = editedSubvention.projects
			? editedSubvention.projects
			: [];
		const newList = [...currentProjects];
		newList.push({
			id: init.projects[0].id,
			name: init.projects[0].name,
			analytic_code: init.projects[0].analytic_code,
			m2m_subvention_project: {
				id: init.projects[0].subventions[0]?.m2m_subvention_project.id,
				percent: 100,
			},
		});

		await setEditedSubvention({ ...editedSubvention, projects: newList });
		await setEditedSection({ ...editedSection, subAccountingInfos: true });
	};

	const handleShowRemoveProject = async () => {
		setShowRemoveProject(!showRemoveProject);
	};

	const handleEditProject = async (evt) => {
		const { name, analyticcode } = evt.target.selectedOptions[0].dataset;
		const { index } = evt.target.dataset;
		const newList = [...editedSubvention.projects];

		newList[index] = {
			id: parseInt(evt.target.value),
			name,
			analytic_code: analyticcode,
			m2m_subvention_project: { percent: 100 },
		};

		setEditedSubvention({ ...editedSubvention, projects: newList });
		setEditedSection({ ...editedSection, subAccountingInfos: true });
	};

	const handleEditPercent = async (evt) => {
		const { value } = evt.target;
		const settedValue = value === '' ? '' : parseInt(evt.target.value);
		const { index } = evt.target.dataset;
		const newList = [...editedSubvention.projects];

		newList[index] = {
			...newList[index],
			m2m_subvention_project: {
				...newList[index]['m2m_subvention_project'],
				percent: settedValue,
			},
		};

		setEditedSubvention({ ...editedSubvention, projects: newList });
		setEditedSection({ ...editedSection, subAccountingInfos: true });
	};

	const handleClickRemoveProject = async (evt) => {
		let { index, section } = evt.target.dataset;
		if (evt.target.localName === 'path') {
			index = evt.target.parentNode.dataset.index;
			section = evt.target.parentNode.dataset.section;
		}
		const newList = [...editedSubvention.projects];
		newList.splice(index, 1);

		setEditedSubvention({ ...editedSubvention, projects: [...newList] });
		setShowRemoveProject(!showRemoveProject);
		setEditedSection({ ...editedSection, [section]: true });
	};

	const handleAddReciever = async () => {
		const newList = [...editedSubvention.receivers];

		if (init.recievers[0]?.id) {
			newList.push({
				id: init.recievers[0].id,
				name: init.recievers[0].name,
			});
		}
		setEditedSubvention({ ...editedSubvention, receivers: newList });
		setEditedSection({ ...editedSection, subAccountingInfos: true });
	};

	const handleShowRemoveReciever = async () => {
		setShowRemoveReciever(!showRemoveReciever);
	};

	const handleEditReciever = async (evt) => {
		const { name } = evt.target.selectedOptions[0].dataset;
		const { index } = evt.target.dataset;
		const newList = [...editedSubvention.receivers];

		newList[index] = { id: evt.target.value, name };

		setEditedSubvention({ ...editedSubvention, receivers: newList });
		setEditedSection({ ...editedSection, subAccountingInfos: true });
	};

	const handleClickRemoveReciever = async (evt) => {
		let { index, section } = evt.target.dataset;
		if (evt.target.localName === 'path') {
			index = evt.target.parentNode.dataset.index;
			section = evt.target.parentNode.dataset.section;
		}
		const newList = [...editedSubvention.receivers];
		newList.splice(index, 1);

		setEditedSubvention({ ...editedSubvention, receivers: newList });
		setShowRemoveReciever(!showRemoveReciever);
		setEditedSection({ ...editedSection, [section]: true });
	};

	console.log(
		'[SubventionRelationInfos.jsx]{L:153} editedSubvention: ',
		editedSubvention
	);
	const projectsListHTML =
		editedSubvention?.projects?.length > 0
			? editedSubvention?.projects?.map((item, i) => {
					if (!user.subvention_modify_right) {
						return (
							<span
								key={
									'project' +
									item.analytic_code +
									i +
									'36bb4d0f-4945-4f4f-ab09-d02b50577d1a'
								}
							>
								{`${item.name} - ${item.analytic_code} -- ${item?.percent} % ,`}
							</span>
						);
					} else {
						return !editable ? (
							<span
								key={
									'text' +
									item.analytic_code +
									i +
									'c5c2957c-d855-41ee-84a2-01d7896a546a'
								}
							>
								{`${item.name} - ${item.analytic_code} -- ${item.m2m_subvention_project?.percent} % ,`}
							</span>
						) : (
							<>
								<select
									key={
										'edit' +
										item.analytic_code +
										i +
										'de216471-dcfa-495d-87d4-2740fe6fdc8c'
									}
									id={`projects`}
									name={item.name}
									data-index={i}
									data-attribute={'name'}
									data-section={'subAccountingInfos'}
									value={editedSubvention.projects[i].id}
									onChange={handleEditProject}
									autoComplete="true"
								>
									{init.projects.map((project, i) => (
										<option
											key={
												'optionProject' +
												project.id +
												'-' +
												i +
												'-' +
												project.analytic_code
											}
											value={project.id}
											data-name={project.name}
											data-analyticcode={project.analytic_code}
										>{`${project.name} - ${project.analytic_code}`}</option>
									))}
								</select>

								<div
									key={'div' + item.name + '-' + i}
									className="input-group suffix"
								>
									<input
										type="text"
										key={'input' + item.name + '-' + i}
										value={editedSubvention.projects[i]?.percent}
										id={'sub-percent-' + i}
										className="sub-percent"
										onChange={handleEditPercent}
										data-index={i}
										data-attribute={'percent'}
										data-section={'subAccountingInfos'}
									/>
									<span
										key={
											'percent' +
											item.name +
											'253f25d4-e47f-43bf-94d4-4f3fd45f7f07' +
											i
										}
										className="input-group-addon "
									>
										%
									</span>
								</div>

								{showRemoveProject && (
									<>
										<IoClose
											key={
												'close' +
												item.name +
												'c825acb7-4776-483c-be80-ab7a3ce28d77' +
												i
											}
											data-id={item.id}
											data-index={i}
											size={30}
											data-section={'subAccountingInfos'}
											onClick={handleClickRemoveProject}
											data-tip={'Retirer le projet'}
										/>
										<ReactTooltip
											key={
												'remove' +
												'-' +
												item.name +
												'-' +
												i +
												'f278da94-7846-4f7d-b24a-382139e531e6'
											}
											place={'top'}
											effect={'solid'}
											className={'tooltipCustom subventionDocumentTooltip test'}
											multiline={true}
										/>
									</>
								)}
							</>
						);
					}
			  })
			: null;

	const recieversListHTML =
		editedSubvention?.receivers?.length < 1 ? (
			<></>
		) : (
			editedSubvention?.receivers?.map((item, i) => {
				if (!user.subvention_modify_right) {
					return (
						<span
							key={'noModifyRight' + item.name + '_' + i}
						>{`${item.name}, `}</span>
					);
				} else {
					return !editable ? (
						<span id={`receivers`} key={'text' + '-' + item.name + '-' + i}>
							{editedSubvention.receivers[i].name}
						</span>
					) : (
						<>
							<select
								id={`receivers`}
								key={'edit' + '-' + item.name + '-' + i}
								name={'reciever_id'}
								data-index={i}
								data-recievername={item.name}
								data-attribute={'name'}
								data-section={'subAccountingInfos'}
								value={editedSubvention.receivers[i].id}
								onChange={handleEditReciever}
							>
								{init.recievers.map((center, i) => (
									<option
										key={'reciever' + center.name + '-' + i + '-' + center.id}
										data-name={center.name}
										value={center.id}
									>
										{center.name}
									</option>
								))}
							</select>
							{showRemoveReciever && (
								<>
									<IoClose
										key={'remove' + '-' + item.name + '-' + i}
										data-id={item.id}
										data-index={i}
										size={30}
										data-section={'subAccountingInfos'}
										onClick={handleClickRemoveReciever}
										data-tip={'Retirer le centre receveur'}
									/>
									<ReactTooltip
										key={'tooltip' + '-' + item.name + '-' + i}
										place={'top'}
										effect={'solid'}
										className={'tooltipCustom subventionDocumentTooltip test'}
										multiline={true}
									/>
								</>
							)}
						</>
					);
				}
			})
		);

	return (
		<div className={'SubventionRelationInfos'}>
			<section
				key={'e4081e57-9f65-44d6-a855-b60b9e477a87'}
				className={'sub-accounting-infos'}
			>
				<div
					key={'e1f8517b-3e56-4198-88a8-985c4db58abd'}
					className="accounting-item-projects"
				>
					<div key={'3659f39d-aebd-4f1d-b310-81fc7a6f9cc5'}>
						{contents.titles.SubventionRelationInfos.projects}
					</div>
					{!editable ? (
						<></>
					) : (
						<>
							<ImPlus
								key={'27cbb71f-da59-4fc8-8b51-8518f62dbb79'}
								onClick={handleAddProject}
								id={'projects'}
								data-tip={'Ajouter un projet'}
							/>
							{editedSubvention?.projects?.length > 0 && (
								<GoPencil
									key={'0713053b-c3e5-422f-85ab-0d5e436e8e55'}
									size={22}
									onClick={handleShowRemoveProject}
									data-tip={'Modifier les projets'}
								/>
							)}
						</>
					)}
					<div
						key={'b82bab64-1056-4973-838d-34d00fc8d78f'}
						className={'projects-list'}
					>
						{projectsListHTML}
					</div>
				</div>
				<div
					key={'67c7afff-c678-4836-9b69-54459cf78b21'}
					className="accounting-item-recievers"
				>
					<div key={'a0f4b774-ff10-48f5-a1e2-07e230eb25d2'}>
						{contents.titles.SubventionRelationInfos.receivers}
					</div>
					{!editable ? (
						<></>
					) : (
						<>
							<ImPlus
								onClick={handleAddReciever}
								key={'6a9d57d1-b80e-4674-b1ad-b8232099d936'}
								id={'recievers'}
								data-tip={'Ajouter un centre receveur'}
							/>
							{editedSubvention?.recievers?.length > 0 && (
								<GoPencil
									size={22}
									onClick={handleShowRemoveReciever}
									key={'cef72216-6989-4d60-9006-bae1bd83b925'}
									data-tip={'Modifier les centres receveurs'}
								/>
							)}
						</>
					)}
					<div
						key={'a89aae6b-0a70-41ab-a346-7454ed9fa9b6'}
						className={'recievers-list'}
					>
						{init.recievers.length > 0
							? recieversListHTML
							: 'Aucun centre bénéficaire configuré'}
					</div>
				</div>
			</section>
			{editedSection.subAccountingInfos && (
				<Buttons
					key={'62afb6fd-5405-46ea-85da-57b370ceca66'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

export default SubventionRelationInfos;
