/**
 * Import setup
 */

/**
 * Import modules
 */
import moment from 'moment';
import axios from 'axios';

/**
 * Import locals
 */
import config from './config.json';

const utility = {
	apiURL: config.APIURL,

	version: '1.3',

	addHours: (numOfHours, date = new Date()) => {
		date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

		return date;
	},

	getDurations: (startDate, endDate) => {
		const start = moment(new Date(startDate));
		const end = moment(new Date(endDate));
		let duration = Math.round(moment(end).diff(start, 'days') / 30);

		let nDuration = null;

		if (moment(end).year() === moment().year()) {
			if (moment(start).year() === moment().year()) {
				nDuration = Math.round(
					moment.duration(moment(end).diff(moment(start))).asMonths()
				);
			} else {
				nDuration = Math.round(
					moment.duration(moment(end).diff(moment().set('month', 0))).asMonths()
				);
			}
		} else if (moment(end).year() > moment().year()) {
			if (moment(start).year() === moment().year()) {
				nDuration = Math.round(
					moment
						.duration(
							moment().set('month', 11).endOf('month').diff(moment(start))
						)
						.asMonths()
				);
			} else {
				nDuration = Math.round(
					moment
						.duration(
							moment()
								.set('month', 11)
								.endOf('month')
								.diff(moment().set('month', 0))
						)
						.asMonths()
				);
			}
		}

		nDuration = parseInt(nDuration);

		if (isNaN(nDuration)) {
			nDuration = 0;
		}

		if (!startDate || !endDate) {
			duration = 0;
			nDuration = 0;
		}

		return { duration, nDuration };
	},

	setValidDate: (date) => {
		let validDate;
		if (!moment(date).isValid()) {
			let from;
			date.includes('-') ? (from = date.split('-')) : (from = date.split('/'));

			validDate = moment(new Date(from[2], from[1] - 1, from[0]));
		} else {
			let from = null;
			const regex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g;
			if (date.toString().match(regex)) {
				from = date.split('/');
				validDate = moment(new Date(from[2], from[1] - 1, from[0]));
			} else {
				validDate = moment(date);
			}
		}

		return validDate;
	},

	dateFormat: (date) => {
		let formatedDate;
		if (!moment(date).isValid()) {
			const from = date.split('-');
			formatedDate = moment(new Date(from[2], from[1] - 1, from[0])).format(
				'DD/MM/YYYY'
			);
		} else {
			formatedDate = moment(date).format('DD/MM/YYYY');
		}
		return formatedDate;
	},

	dateShorter: (date) => {
		let formatedDate;

		const from = date.split('/');
		formatedDate = moment(new Date(from[2], from[1] - 1, from[0])).format(
			'DD/MM/YY'
		);

		return formatedDate;
	},

	regexValidation: (user, isValid) => {
		const editIsValid = { ...isValid };
		const usernameRegex = new RegExp('^(?=.{1,})');
		const passwordRegex = new RegExp(
			'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})'
		);
		const confirmPasswordRegex = new RegExp('^(?=.{1,})');
		const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
		const phoneRegex = new RegExp(
			'(\\d{2} )(\\d{2} )(\\d{2} )(\\d{2} )(\\d{2})'
		);
		const roleRegex = new RegExp('\\d{1,}');
		const defaultRegex = new RegExp('^(?=.{1,})');

		for (const [key, value] of Object.entries(user)) {
			isValid[key] = false;
			switch (key) {
				case 'first_name':
					usernameRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'last_name':
					usernameRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'password':
					passwordRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'confirmePassword':
					if (confirmPasswordRegex.test(value)) {
						value === user.password
							? (editIsValid[key] = true)
							: (editIsValid[key] = false);
					}
					break;
				case 'email':
					emailRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'phone':
					phoneRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'roleId':
					roleRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'role_id':
					roleRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'status':
					defaultRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'subvention_modify_right':
					defaultRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'subvention_extract_right':
					defaultRegex.test(value)
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				case 'association_id':
					defaultRegex.test(value)
						? (editIsValid[key] = true)
						: user.roleId === 3
						? (editIsValid[key] = true)
						: (editIsValid[key] = false);
					break;
				default:
					break;
			}
		}
		return editIsValid;
	},

	compare: (a, b) => {
		if (a.status < b.status) {
			return -1;
		}
		if (a.status > b.status) {
			return 1;
		}
		return 0;
	},

	passwordValidation: (password) => {
		const passwordRegex = new RegExp(
			'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})'
		);

		return passwordRegex.test(password);
	},
	/**
	 * Formated axios request
	 * @param {string} method  method ( GET, POST, PUT, DELETE)
	 * @param {string} url request url
	 * @param {string} token auth token
	 * @param {{}} data datas
	 * @returns {Promise}
	 */
	axiosReq: async (method, url, token, data = null) => {
		const options = {
			method,
			url,
			data,
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		return await axios(options);
	},

	getToken: () => {
		return localStorage.getItem('token');
	},

	setToken: (token = null) => {
		if (!token) {
			return localStorage.removeItem('token');
		}
		return localStorage.setItem('token', token);
	},

	recalcTotalRecieved: (itemArray) => {
		if (itemArray.length > 0) {
			const reducer = (previousValue, currentValue) =>
				previousValue + currentValue;
			const amountArray = itemArray.map((payment) => payment.amount);
			if (amountArray.length > 0) {
				return amountArray.reduce(reducer);
			}
		} else {
			return 0;
		}
	},

	sorter: (list, property, value) => {
		return list.sort((a, b) => {
			if (value === 'ASC') {
				if (property === 'funder') {
					if (a.funder.name < b.funder.name) {
						return -1;
					}
					if (a.funder.name > b.funder.name) {
						return 1;
					}
					return 0;
				} else if (property === 'end_date' || property === 'start_date') {
					const timestamp1 = new Date(
						a[property].split('/').reverse().join('/')
					).getTime();
					const timestamp2 = new Date(
						b[property].split('/').reverse().join('/')
					).getTime();

					if (timestamp1 < timestamp2) {
						return -1;
					}
					if (timestamp1 > timestamp2) {
						return 1;
					}
				} else if (!isNaN(a[property])) {
					if (parseFloat(a[property]) < parseFloat(b[property])) {
						return -1;
					}
					if (parseFloat(a[property]) > parseFloat(b[property])) {
						return 1;
					}
					return 0;
				} else {
					if (a[property] < b[property]) {
						return -1;
					}
					if (a[property] > b[property]) {
						return 1;
					}
					return 0;
				}
			}
			if (value === 'DESC') {
				if (property === 'funder') {
					if (a.funder.name > b.funder.name) {
						return -1;
					}
					if (a.funder.name < b.funder.name) {
						return 1;
					}
					return 0;
				} else if (property === 'end_date' || property === 'start_date') {
					const timestamp1 = new Date(
						a[property].split('/').reverse().join('/')
					).getTime();
					const timestamp2 = new Date(
						b[property].split('/').reverse().join('/')
					).getTime();

					if (timestamp1 > timestamp2) {
						return -1;
					}
					if (timestamp1 < timestamp2) {
						return 1;
					}
				} else if (!isNaN(a[property])) {
					if (parseFloat(a[property]) > parseFloat(b[property])) {
						return -1;
					}
					if (parseFloat(a[property]) < parseFloat(b[property])) {
						return 1;
					}
					return 0;
				} else {
					if (a[property] > b[property]) {
						return -1;
					}
					if (a[property] < b[property]) {
						return 1;
					}
					return 0;
				}
			}
		});
	},

	calculateAge: (dateOfBirth) => {
		const today = new Date();
		const birthDate = new Date(dateOfBirth);

		let age = today.getFullYear() - birthDate.getFullYear();

		// Vérifier si l'anniversaire de cette année n'est pas encore passé
		const hasBirthdayPassed =
			today.getMonth() > birthDate.getMonth() ||
			(today.getMonth() === birthDate.getMonth() &&
				today.getDate() > birthDate.getDate());

		if (!hasBirthdayPassed) {
			age--;
		}

		return age;
	},

	calculatePercent: (total, value) => {
		return Math.round((value / total) * 100);
	},
};

export default utility;
