/**
 * Import modules
 */
import React from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */

/**
 * Import contexts
 */
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectTotals.scss';
import contents from '../../contents/contents.json';

const ProjectTotals = () => {
	const { totals } = useProjectContext();

	return (
		<section
			key={'6fe6aa31-c202-49be-be7d-a4d07b477d70'}
			className={'ProjectTotals project-financial-infos'}
		>
			<table
				className="financial_totals"
			>
				<tr
					className="financial_totals_rows"
				>
					<th >{contents.ProjectTotals.ttlSubvention}</th >
					<td >
						<NumericFormat
							key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
							value={totals.subventions}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</td >
				</tr >
				<tr
					className="financial_totals_rows"
				>
					<th >{contents.ProjectTotals.ttlSelfFinancing}</th >
					<td >
						<NumericFormat
							key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
							value={totals.self_financing}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</td >
				</tr >
				<tr
					className="financial_totals_rows"
				>
					<th
						className="great_total"
					>{contents.ProjectTotals.ttl}</th >
					<td
						className="great_total"
					>
						<NumericFormat
							key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
							value={totals.total}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</td >
				</tr >
			</table >
		</section >
	);
};

export default ProjectTotals;
