/**
 * Import modules
 */
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { ImPlus } from 'react-icons/im';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';
import AddPaymentModal from './modals/AddPaymentModal';

/**
 * Import Context
 */
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './SubventionFinancialInfos.scss';
import utility from '../../utility';
import contents from '../../contents/contents.json';

/**
 * Local functions
 */
const compareVersements = (a, b) => {
	if (a.date > b.date) {
		return -1;
	}
	if (a.date < b.date) {
		return 1;
	}
	return 0;
};

const SubventionFinancialInfos = ({ editable, handleEditSubmit }) => {
	const {
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
		showAddPaymentModal,
		setShowAddPaymentModal,
	} = useSubventionContext();

	const handleAskedAmountChange = async (values, sourceInfos) => {
		if (sourceInfos.event) {
			const { value } = values;

			setEditedSubvention({
				...editedSubvention,
				amount_asked: parseFloat(value),
			});

			await setEditedSection({
				...editedSection,
				subFinancialInfos: true,
			});
		}
	};

	const handleContractedAmountChange = async (values, sourceInfos) => {
		if (sourceInfos.event) {
			let { value } = values;

			setEditedSubvention({
				...editedSubvention,
				amount_contracted: value === '' ? null : parseFloat(value),
				remains:
					editedSubvention.total_recieved === 0 ||
					editedSubvention.total_recieved === '0'
						? value === ''
							? 0
							: parseFloat(value)
						: parseFloat(editedSubvention.total_recieved) - parseFloat(value),
			});

			await setEditedSection({
				...editedSection,
				subFinancialInfos: true,
			});
		}
	};

	const handleClickShowModalAddPayment = () => {
		setShowAddPaymentModal(true);
	};

	const switchShowModal = () => setShowAddPaymentModal(!showAddPaymentModal);
	console.log(
		'[SubventionFinancialInfos.jsx]{L:94} editedSubvention?.payments[0]: ',
		editedSubvention?.payments?.length > 0 && editedSubvention?.payments[0]
	);
	console.log(
		'[SubventionFinancialInfos.jsx]{L:94} ditedSubvention?.payments[0].date: ',
		editedSubvention?.payments?.length > 0 &&
			editedSubvention?.payments[0].creation_date
	);

	const versementsHTML =
		editedSubvention?.payments?.length > 0 ? (
			editedSubvention?.payments?.sort(compareVersements).map((item, i) => (
				<div
					className={'payment-item'}
					key={`payment-item_${item.creation_date}_${item.amount}_${i}`}
				>
					<div
						key={`payment-item_div_${item.creation_date}_${item.amount}_${i}`}
						className={'payment-item-1'}
					>
						{utility.dateFormat(item.creation_date)}{' '}
					</div>

					<NumericFormat
						key={`numeric-amount_${item.creation_date}_${item.amount}_${i}`}
						value={item.amount}
						id={'contractedAmount'}
						displayType={'text'}
						thousandSeparator={' '}
						suffix={' €'}
						decimalScale={2}
						className="payment-item-3"
						allowNegative={false}
						data-section={'subFinancialInfos'}
					/>
				</div>
			))
		) : (
			<div key={'no_payment-item'} />
		);

	return (
		<div className={'SubventionFinancialInfos'}>
			<section
				key={'63dd41f6-7fe6-47c3-afad-9093704919cb'}
				className={'sub-financial-infos'}
			>
				<div
					key={'f8d1476c-54df-48b4-afd6-cdb00512a54f'}
					className="financial-infos-amount-asked"
				>
					<label
						key={'a0b213c1-b403-4ada-90a3-f71428d7be73'}
						htmlFor="amount_asked"
					>
						{contents.labels.askedAmount}
					</label>
					<div key={'e02bd4e2-ca9e-4c32-be0a-81c9525ab57a'}>
						<NumericFormat
							key={'350f09ee-2732-4a7a-8141-d41db8fc3e5f'}
							value={editedSubvention.amount_asked}
							id={'amount_asked'}
							displayType={editable ? 'input' : 'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
							onValueChange={handleAskedAmountChange}
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'48ef3946-2f17-4742-9256-56b72ce4acde'}
					className="financial-infos-amount_contracted"
				>
					<label
						key={'5367bb29-2b02-4885-94f5-5dd96fcc301c'}
						htmlFor="amount_contracted"
					>
						{contents.labels.contractedAmount}
					</label>
					<div key={'20c5cc6b-cea5-465f-9bb3-3c3eb1f1f540'}>
						<NumericFormat
							key={'da49bc35-15dd-4655-93a4-56b64679138e'}
							value={editedSubvention.amount_contracted}
							id={'amount_contracted'}
							displayType={editable ? 'input' : 'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
							onValueChange={handleContractedAmountChange}
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'b314c713-be05-4ebd-99cb-2b500b73575d'}
					className="financial-infos-total_recieved"
				>
					<label
						key={'76feea91-c610-42e1-b2fa-3fa4c1627def'}
						htmlFor="total_recieved"
					>
						{contents.labels.recieved}
					</label>
					<div
						key={'ddcaa7810-6957-4d5a-9b5d-4416d2f3a508'}
						id={'total_recieved'}
					>
						<NumericFormat
							key={'8ffd6eea-1694-48cc-aa2c-14451fc72579'}
							value={editedSubvention.total_recieved}
							id={'contractedAmount'}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'3c3d561c-1bf6-4bf9-9553-d47e5507002d'}
					className="financial-infos-remains"
				>
					<label key={'767492d0-8584-4d21-ba52-255f6564f477'} htmlFor="remains">
						{contents.labels.remains}
					</label>
					<div key={'86c53391-4ba7-4229-99db-3c1265e44302'} id={'remains'}>
						<NumericFormat
							key={'566479d2-0678-4f04-aaf9-3993ae94f23c'}
							value={editedSubvention.remains}
							id={'contractedAmount'}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							allowNegative={true}
							decimalScale={2}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'020670e0-465e-4898-ad19-3c14aa99c320'}
					className="financial-infos-give_back"
				>
					<label
						key={'0254862a5-e4e4-4473-8f11-122ecbda108c'}
						htmlFor="give_back"
					>
						{contents.labels.giveBack}
					</label>

					<div key={'f952b2ab-caeb-4322-885c-57e9290685d4'} id={'give_back'}>
						<NumericFormat
							key={'358406a9-3a7e-436a-9efa-db6a639546ea'}
							value={editedSubvention.give_back}
							id={'contractedAmount'}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="totaux-read"
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'184cef7d-241f-40f5-80c7-d0ffaed337d2'}
					className="financial-infos-percent"
				>
					<label key={'c7f46d37-3ef0-472f-b991-ba38c8730ea0'} htmlFor="percent">
						{contents.labels.quote}
					</label>
					<div key={'0c320a6a-d8dc-4d93-bcfb-4725cd699d79'} id={'percent'}>
						<NumericFormat
							key={'c7445d1f-cf62-41db-9d6e-7a71dec6e3d5'}
							// value={
							// 	((editedSubvention.remains * 100) /
							// 		editedSubvention.amount_contracted) %
							// 		1 !==
							// 	0
							// 		? (
							// 				(editedSubvention.remains * 100) /
							// 				editedSubvention.amount_contracted
							// 		  ).toFixed(3)
							// 		: (editedSubvention.remains * 100) /
							// 		  editedSubvention.amount_contracted
							// }
							value={utility.calculatePercent(
								editedSubvention.amount_contracted,
								editedSubvention.remains
							)}
							id={'contractedAmount'}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' %'}
							decimalScale={2}
							className="totaux-read"
							allowNegative={false}
							data-section={'subFinancialInfos'}
						/>
					</div>
				</div>
				<div
					key={'bf4411bb-648d-4c05-b3cd-3a1fd1f923ce'}
					className="financial-infos-payments"
				>
					<label
						key={'bb2c3296-b274-477f-b6bd-72a22ed4d06d'}
						htmlFor="payments"
					>
						{contents.labels.versements}
					</label>
					{!editable ? (
						<></>
					) : (
						<ImPlus
							key={'33e84f2a-6e0d-4ac6-8ffb-501cedc8788c'}
							onClick={handleClickShowModalAddPayment}
							id={'payments'}
							data-tip={'Ajouter un versement'}
						/>
					)}
					<div key={'1d876b29-ae22-43b9-8784-5bde30afac1a'} id={'payments'}>
						{versementsHTML}
					</div>
				</div>
			</section>
			{editedSection.subFinancialInfos && (
				<Buttons
					key={'d86923e2-f915-4f3b-a011-1693a5a6b285'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
			<AddPaymentModal
				showModal={showAddPaymentModal}
				closeModal={switchShowModal}
			/>
		</div>
	);
};

export default SubventionFinancialInfos;
