import React, { createContext, useContext, useEffect, useState } from 'react';

// context creation
export const formContext = createContext(false);

// context hook
export const useProjectContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'ProjectContext should not be undefined. Did you forget to wrap your component with ProjectContextProvider ?'
		);
	}
	return context;
};

const initialProject = {
	id: '',
	name: '',
	description: '',
	start_date: '',
	end_date: '',
	subventions: [],
	self_financings: [],
	analytic_code: '',
	etat: '',
	manager: {
		email: '',
		phone: '',
		last_name: '',
		first_name: '',
	},
	unique_key: '',
	associationIId: '',
	documents: [],
};

const initialTotals = {
	subventions: 0,
	self_financing: 0,
	total: 0,
};
const initialNewSelf = {
	origin: '',
	amount: '',
};

const initialEditedSection = {
	projectGeneralInfos: false,
	projectSubventionInfos: false,
	projectParticipants: false,
	'project-danger-zone': false,
};

const initialNewParticipant = {
	last_name: '',
	first_name: '',
	email: '',
	birthday: '',
	address: '',
	zipcode: '',
	city: '',
	association_id: '',
	project_id: '',
	presence: false,
};

// provider creation
export const ProjectContextProvider = ({ children }) => {
	const [selectedProject, setSelectedProject] = useState(initialProject);
	const [editedProject, setEditedProject] = useState(selectedProject);
	const [editedSection, setEditedSection] = useState(initialEditedSection);

	const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

	const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
	const switchShowDeleteProjectModal = () =>
		setShowDeleteProjectModal(!showDeleteProjectModal);

	const [percentValue, setPercentValue] = useState([]);

	const [totals, setTotals] = useState(initialTotals);

	const [showAddSelfModal, setShowAddSelfModal] = useState(false);
	const switchShowAddSelfModal = () => setShowAddSelfModal(!showAddSelfModal);
	const [newSelf, setNewSelf] = useState(initialNewSelf);

	const [participants, setParticipants] = useState([]);
	const [newParticipant, setNewParticipant] = useState(initialNewParticipant);
	const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);

	const [employees, setEmployees] = useState([]);
	const [projectEmployee, setProjectEmployee] = useState([]);

	const switchShowAddParticipantModal = () =>
		setShowAddParticipantModal(!showAddParticipantModal);

	const switchShowAddDocumentModal = () =>
		setShowAddDocumentModal(!showAddDocumentModal);

	const editCancel = () => {
		setEditedSection(initialEditedSection);
		setEditedProject({ ...selectedProject, description: '' });
		setShowDeleteProjectModal(false);
		setShowAddSelfModal(false);
		setNewSelf(initialNewSelf);
		setParticipants(selectedProject.beneficiaries);
		setNewParticipant(initialNewParticipant);
		setShowAddParticipantModal(false);
		setShowAddDocumentModal(false);
	};

	const resetSection = () => {
		setEditedSection(initialEditedSection);
	};

	/**
	 * Set totals
	 */
	useEffect(() => {
		if (editedProject.id) {
			/**
			 * add all subvention's amount part
			 */
			let totalSub = 0;
			let totalsSelf = 0;
			editedProject.subventions?.map((sub) => {
				const realAmount =
					(sub.amount_contracted * sub.m2m_subvention_project.percent) / 100;
				totalSub += realAmount;
			});

			editedProject.self_financings?.forEach((element) => {
				totalsSelf += element.amount;
			});

			const total = totalSub + totalsSelf;
			setTotals({
				...totals,
				subventions: totalSub,
				self_financing: totalsSelf,
				total,
			});

			setNewSelf({ ...newSelf, project_id: editedProject.id });

			const editPercentValue = [...percentValue];
			editedProject.subventions?.forEach((sub, i) => {
				editPercentValue[i] =
					(sub.amount_contracted * sub.m2m_subvention_project.percent) / 100;
			});
			setPercentValue(editPercentValue);
		}
	}, [editedProject]);

	let value = {
		editedSection,
		setEditedSection,
		selectedProject,
		setSelectedProject,
		editedProject,
		setEditedProject,
		showDeleteProjectModal,
		switchShowDeleteProjectModal,
		totals,
		setTotals,
		showAddSelfModal,
		switchShowAddSelfModal,
		newSelf,
		setNewSelf,
		percentValue,
		setPercentValue,
		participants,
		setParticipants,
		newParticipant,
		setNewParticipant,
		showAddParticipantModal,
		switchShowAddParticipantModal,
		editCancel,
		resetSection,
		showAddDocumentModal,
		switchShowAddDocumentModal,
		employees,
		setEmployees,
		projectEmployee,
		setProjectEmployee,
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
