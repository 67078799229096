/**
 * Import modules
 */
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

/**
 * Import components
 */
import ProjectGeneralInfos from './ProjectGeneralInfos';
import ProjectParticipants from './ProjectParticipants';
import ProjectSubventionInfos from './ProjectSubventionInfos';
import ProjectTotals from './ProjectTotals';
import ProjectDangerZone from './ProjectDangerZone';
import { ProjectDocuments } from './ProjectDocuments';
import { ProjectUser } from './ProjectUser';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useKeyContext } from '../../contexts/keyContext';
import { useProjectContext } from '../../contexts/projectContext';
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './ProjectDetail.scss';
import {
	getOneProjectListById,
	editProject,
} from '../../requests/projectsRequests';
import config from '../../config.json';
import contents from '../../contents/contents.json';
import { deleteFiles } from '../../requests/filesRequest';

// const { env } = config;

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const editedSectionInit = {
	projectGeneralInfos: false,
	projectSubventionInfos: false,
	'project-danger-zone': false,
};

const ProjectDetail = ({ user, setItemEdited }) => {
	const {
		editedSection,
		setEditedSection,
		setSelectedProject,
		editedProject,
		setEditedProject,
		setParticipants,
	} = useProjectContext();

	const {
		fileToDelete,
		setFileToDelete,
		setFileDeleteErrorMessage,
		setReload,
	} = useSubventionContext();
	const { loading, actions } = useLoadingContext();
	const { keySetter } = useKeyContext();

	let projectId = useQuery().get('id');
	let location = useLocation();

	const handleEditSubmit = async (event) => {
		actions.setter(true, true);
		event.preventDefault();

		if (editedSection.subDocuments && fileToDelete.length > 0) {
			const res = await deleteFiles(fileToDelete, 'subvention');

			if (res.status !== 200) {
				switch (res.message.code) {
					case 'ENOENT':
						setFileDeleteErrorMessage(contents.messages.fileNotFound);
						break;

					default:
						setFileDeleteErrorMessage(res.message);
				}
				setFileToDelete([]);
			} else {
				setReload(true);
				setFileToDelete([]);
				setFileDeleteErrorMessage(null);
			}
		}

		const result = await editProject(editedProject);

		if (result) {
			if (result.status === 200) {
				await setEditedProject({ ...result.data });
				await setSelectedProject({ ...result.data });
				await setItemEdited(true);
			}
		}

		await setEditedSection(editedSectionInit);
		actions.setter(false, true);
	};

	/**
	 * Get project detail
	 * Trigger: user
	 */
	useEffect(() => {
		(async () => {
			await actions.setter(true);
			if (location.pathname.includes('/dashboard/projects')) {
				keySetter('projects');
			}
			if (user.id) {
				const result = await getOneProjectListById(projectId);
				if (result.data) {
					await setSelectedProject(result.data);
					await setEditedProject({ ...result.data, edited: false });

					await setParticipants(result.data.beneficiaries);
				}
				await actions.setter(false);
			}
		})();
	}, [user, location.pathname]);

	return (
		<div className={'ProjectDetail'}>
			<NavLink
				key={'e2c95296-78bf-4724-8ab6-4c5a220860fe'}
				className={'return-button'}
				to={'/dashboard'}
			>
				{contents.links.goBack}
			</NavLink>

			{loading ? (
				<span className="loading">
					<Spinner
						as="span"
						animation="border"
						role="status"
						aria-hidden="true"
					/>
					<span className="spinner-text">{contents.messages.loadingText}</span>
				</span>
			) : (
				<>
					<ProjectGeneralInfos handleEditSubmit={handleEditSubmit} />

					{['admin', 'manager'].includes(user?.role?.title) && <ProjectUser />}

					{['admin', 'manager'].includes(user?.role?.title) && (
						<ProjectSubventionInfos handleEditSubmit={handleEditSubmit} />
					)}

					<ProjectDocuments handleEditSubmit={handleEditSubmit} />

					{['admin', 'manager'].includes(user?.role?.title) && (
						<ProjectTotals />
					)}

					<ProjectParticipants />

					{user.project_modify_right && <ProjectDangerZone />}
				</>
			)}
		</div>
	);
};

export default ProjectDetail;
