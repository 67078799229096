/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaSyncAlt } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './SearchBar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import utility from '../../utility';
import moment from 'moment';
import contents from '../../contents/contents.json';

const SearchBar = ({ keyType, itemList, setter }) => {
	const { loading } = useLoadingContext();

	const [inputValue, setInputValue] = useState('');
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const handleInputChange = async (evt) => {
		await setInputValue(evt.target.value);
	};

	const handleStartDate = async (date) => {
		setStartDate(date);
	};
	const handleEndDate = async (date) => {
		setEndDate(date);
	};

	const handleResetFilter = async (evt) => {
		evt.preventDefault();
		setInputValue('');
		setStartDate(null);
		setEndDate(null);
	};

	const dateTest = (item) => {
		let result = false;
		let searchStartDate,
			searchEndDate,
			searchBetweenDate = false;

		if (!!startDate && endDate === null) {
			const momentStartDate = moment(utility.setValidDate(startDate));

			searchStartDate = moment(
				utility.setValidDate(item.start_date)
			).isSameOrAfter(momentStartDate);
		}

		if (!!endDate && startDate === null) {
			const momentEndDate = moment(utility.setValidDate(endDate));

			searchEndDate = moment(
				utility.setValidDate(item.end_date)
			).isSameOrBefore(momentEndDate);
		}

		if (!!endDate && !!startDate) {
			const momentStartDate = moment(utility.setValidDate(startDate));
			const momentEndDate = moment(utility.setValidDate(endDate));

			searchBetweenDate =
				moment(utility.setValidDate(item.start_date)).isSameOrAfter(
					momentStartDate
				) &&
				moment(utility.setValidDate(item.end_date)).isSameOrBefore(
					momentEndDate
				);
		}

		if (!startDate && !endDate) {
			result = true;
		}

		if (searchStartDate || searchEndDate || searchBetweenDate) {
			result = true;
		}

		if (inputValue === '') {
			// handle filter with input
		}

		return result;
	};

	const stringTest = (item) => {
		let result = false;
		if (item.funder) {
			item.funderName = item.funder.name;
		}

		for (const itemKey in item) {
			let matchProperty = false;

			if (item[itemKey] instanceof Array || item[itemKey] instanceof Object) {
				matchProperty = false;
			} else {
				if (inputValue === '') {
					matchProperty = true;
				}

				if (inputValue.length > 0) {
					if (!item[itemKey]) {
						matchProperty = false;
					} else {
						matchProperty = isNaN(item[itemKey])
							? item[itemKey]
									.normalize('NFD')
									.replace(/[\u0300-\u036f]/g, '')
									.toLowerCase()
									.includes(
										inputValue
											.normalize('NFD')
											.replace(/[\u0300-\u036f]/g, '')
											.toLowerCase()
									)
							: item[itemKey]
									.toString()
									.normalize('NFD')
									.replace(/[\u0300-\u036f]/g, '')
									.toLowerCase()
									.includes(
										inputValue
											.toString()
											.normalize('NFD')
											.replace(/[\u0300-\u036f]/g, '')
											.toLowerCase()
									);
					}
				}
			}
			if (matchProperty) {
				result = true;
			}
		}

		return result;
	};

	/**
	 * Filter by date and string
	 * Trigger: inputValue, startDate, endDate
	 */
	useEffect(() => {
		(async () => {
			if (itemList.length > 0) {
				const filteredByDate = itemList.filter((item) => dateTest(item));

				const filteredByString = filteredByDate.filter((item) =>
					stringTest(item)
				);

				await setter(filteredByString);
			}
		})();
	}, [inputValue, startDate, endDate]);

	/**
	 * Emptying inputs
	 * Trigger: keyType
	 */
	useEffect(() => {
		setInputValue('');
		setStartDate(null);
		setEndDate(null);
	}, [keyType]);

	return (
		<div
			className={'SearchBar'}
		>
			<input
				id={'word-search'}
				type="search"
				className="search-input"
				value={inputValue}
				onChange={handleInputChange}
				placeholder={contents.placeholders.searchBarInput}
			/>
			<div >
				<DatePicker
					selected={startDate}
					onChange={handleStartDate}
					placeholderText={contents.placeholders.from}
					showYearDropdown
					dateFormatCalendar="MMMM"
					dateFormat="dd/MM/yyyy"
					yearDropdownItemNumber={10}
					scrollableYearDropdown
					isClearable
				/>
			</div >

			<div >
				<DatePicker
					selected={endDate}
					onChange={handleEndDate}
					placeholderText={contents.placeholders.to}
					showYearDropdown
					dateFormatCalendar="MMMM"
					dateFormat="dd/MM/yyyy"
					yearDropdownItemNumber={10}
					scrollableYearDropdown
					isClearable
					minDate={startDate}
				/>
			</div >
			<FaSyncAlt
				onClick={handleResetFilter}
				size={25}
				color={'#47C8F4'}
				data-tip={contents.toolTipDatas.SearchBarReset}
			/>
			{loading && (
				<>
					<Spinner
						as="span"
						animation="border"
						role="status"
						aria-hidden="true"
					/>
					<span
						className="visually-hidden"
					>
						{contents.messages.loadingText}
					</span >
				</>
			)}
		</div >
	);
};

export default SearchBar;
